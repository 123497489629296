import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@net-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionComponent } from './accordion.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IconModule],
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
})
export class AccordionModule {}
