<div class="flex flex-col md:flex-row">
  <div class="flex-grow md:h-screen md:overflow-y-auto">
    <div class="xl:m-8">
      <div class="max-w-screen-2xl mx-auto">
        <div
          class="relative mb-12 flex h-56 items-center justify-center overflow-hidden bg-cover bg-center bg-no-repeat"
          style="background-image: url(assets/images/header-base.jpg)"
        >
          <div class="mx-6 text-center">
            <h1 class="typo-hero">Netural Coding Challenge</h1>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
