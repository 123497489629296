import { Component, OnInit } from '@angular/core';
import { IconsRegistry, TranslationService } from '@net-services';
import { completeIconSet } from '@net-types';

@Component({
  selector: 'net-ch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private iconRegistry: IconsRegistry
  ) {}

  ngOnInit(): void {
    this.translationService.init();
    this.iconRegistry.registerIcons(completeIconSet);
  }
}
